/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateLunchOCRReceiptDTO } from '../dto';
// @ts-ignore
import { CreateLunchReceiptDTO } from '../dto';
// @ts-ignore
import { EmployeeLunchReportsDTO } from '../dto';
// @ts-ignore
import { IdentifierDTO } from '../dto';
// @ts-ignore
import { LunchPeriodDTO } from '../dto';
// @ts-ignore
import { LunchPeriodsDTO } from '../dto';
// @ts-ignore
import { LunchReceiptDTO } from '../dto';
// @ts-ignore
import { LunchReceiptOCRAnalysisDTO } from '../dto';
// @ts-ignore
import { LunchReceiptTenantListDTO } from '../dto';
// @ts-ignore
import { LunchReceiptsDTO } from '../dto';
// @ts-ignore
import { LunchReportDTO } from '../dto';
// @ts-ignore
import { UpdateLunchReceiptDTO } from '../dto';
// @ts-ignore
import { UpdateLunchReceiptOCRLineItemDTO } from '../dto';
// @ts-ignore
import { UploadLinkDTO } from '../dto';
/**
 * LunchApi - axios parameter creator
 * @export
 */
export const LunchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary send a receipt image for synchronous OCR analysis
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchOCRReceiptDTO} createLunchOCRReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeReceipt: async (tenantId: string, employeeId: string, createLunchOCRReceiptDTO: CreateLunchOCRReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('analyzeReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('analyzeReceipt', 'employeeId', employeeId)
            // verify required parameter 'createLunchOCRReceiptDTO' is not null or undefined
            assertParamExists('analyzeReceipt', 'createLunchOCRReceiptDTO', createLunchOCRReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/ocrReceipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLunchOCRReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchReceiptDTO} createLunchReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt: async (tenantId: string, employeeId: string, createLunchReceiptDTO: CreateLunchReceiptDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createReceipt', 'employeeId', employeeId)
            // verify required parameter 'createLunchReceiptDTO' is not null or undefined
            assertParamExists('createReceipt', 'createLunchReceiptDTO', createLunchReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLunchReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink: async (tenantId: string, employeeId: string, contentType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('createUploadLink', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('createUploadLink', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts/createUploadLink`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt: async (tenantId: string, employeeId: string, receiptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('deleteReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('deleteReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('deleteReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the current lunch period. The period will only exist if the lunch benefit is activated for the current user within the current month. Returns the active lunch period of the current user.
         * @summary read the current lunch period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPeriod: async (tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getCurrentPeriod', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getCurrentPeriod', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/periods/current`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list of employees with the lunch benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeReports: async (month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getEmployeeReports', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getEmployeeReports', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/lunch/reports/employees`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all lunch receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLunchReceipts: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getLunchReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getLunchReceipts', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/lunch/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read the receipts of the requested period. The Lunch benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts: async (month: string, tenantId: string, employeeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPeriodReceipts', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriodReceipts', 'employeeId', employeeId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods: async (tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getPeriods', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getPeriods', 'employeeId', employeeId)
            // verify required parameter 'startMonth' is not null or undefined
            assertParamExists('getPeriods', 'startMonth', startMonth)
            // verify required parameter 'endMonth' is not null or undefined
            assertParamExists('getPeriods', 'endMonth', endMonth)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/periods`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startMonth !== undefined) {
                localVarQueryParameter['startMonth'] = startMonth;
            }

            if (endMonth !== undefined) {
                localVarQueryParameter['endMonth'] = endMonth;
            }

            if (maxCount !== undefined) {
                localVarQueryParameter['maxCount'] = maxCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt: async (tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('getReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('getReceipt', 'receiptId', receiptId)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (withImage !== undefined) {
                localVarQueryParameter['withImage'] = withImage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReceiptDetailsExport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/lunch/reports/receiptDetails`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary report values of the lunch benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getReport', 'month', month)
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('getReport', 'tenantId', tenantId)
            const localVarPath = `/tenants/{tenantId}/benefits/lunch/reports`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateLunchReceiptDTO} updateLunchReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt: async (tenantId: string, employeeId: string, receiptId: string, updateLunchReceiptDTO: UpdateLunchReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateReceipt', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateReceipt', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('updateReceipt', 'receiptId', receiptId)
            // verify required parameter 'updateLunchReceiptDTO' is not null or undefined
            assertParamExists('updateReceipt', 'updateLunchReceiptDTO', updateLunchReceiptDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts/{receiptId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (isAudit !== undefined) {
                localVarQueryParameter['isAudit'] = isAudit;
            }

            if (isTenantAudit !== undefined) {
                localVarQueryParameter['isTenantAudit'] = isTenantAudit;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLunchReceiptDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the product classification for an ocr line item 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {string} ocrLineItemId 
         * @param {UpdateLunchReceiptOCRLineItemDTO} updateLunchReceiptOCRLineItemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceiptOcrLineItem: async (tenantId: string, employeeId: string, receiptId: string, ocrLineItemId: string, updateLunchReceiptOCRLineItemDTO: UpdateLunchReceiptOCRLineItemDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenantId' is not null or undefined
            assertParamExists('updateReceiptOcrLineItem', 'tenantId', tenantId)
            // verify required parameter 'employeeId' is not null or undefined
            assertParamExists('updateReceiptOcrLineItem', 'employeeId', employeeId)
            // verify required parameter 'receiptId' is not null or undefined
            assertParamExists('updateReceiptOcrLineItem', 'receiptId', receiptId)
            // verify required parameter 'ocrLineItemId' is not null or undefined
            assertParamExists('updateReceiptOcrLineItem', 'ocrLineItemId', ocrLineItemId)
            // verify required parameter 'updateLunchReceiptOCRLineItemDTO' is not null or undefined
            assertParamExists('updateReceiptOcrLineItem', 'updateLunchReceiptOCRLineItemDTO', updateLunchReceiptOCRLineItemDTO)
            const localVarPath = `/tenants/{tenantId}/employees/{employeeId}/benefits/lunch/receipts/{receiptId}/ocrLineItemId/{ocrLineItemId}`
                .replace(`{${"tenantId"}}`, encodeURIComponent(String(tenantId)))
                .replace(`{${"employeeId"}}`, encodeURIComponent(String(employeeId)))
                .replace(`{${"receiptId"}}`, encodeURIComponent(String(receiptId)))
                .replace(`{${"ocrLineItemId"}}`, encodeURIComponent(String(ocrLineItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLunchReceiptOCRLineItemDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LunchApi - functional programming interface
 * @export
 */
export const LunchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LunchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary send a receipt image for synchronous OCR analysis
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchOCRReceiptDTO} createLunchOCRReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analyzeReceipt(tenantId: string, employeeId: string, createLunchOCRReceiptDTO: CreateLunchOCRReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchReceiptOCRAnalysisDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.analyzeReceipt(tenantId, employeeId, createLunchOCRReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchReceiptDTO} createLunchReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReceipt(tenantId: string, employeeId: string, createLunchReceiptDTO: CreateLunchReceiptDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentifierDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReceipt(tenantId, employeeId, createLunchReceiptDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadLink(tenantId, employeeId, contentType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReceipt(tenantId, employeeId, receiptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the current lunch period. The period will only exist if the lunch benefit is activated for the current user within the current month. Returns the active lunch period of the current user.
         * @summary read the current lunch period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentPeriod(tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchPeriodDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentPeriod(tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list of employees with the lunch benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeReports(month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLunchReportsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeReports(month, tenantId, department, filter, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all lunch receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLunchReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchReceiptTenantListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLunchReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read the receipts of the requested period. The Lunch benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodReceipts(month: string, tenantId: string, employeeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchReceiptsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodReceipts(month, tenantId, employeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriods(tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchPeriodsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriods(tenantId, employeeId, startMonth, endMonth, maxCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchReceiptDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceipt(tenantId, employeeId, receiptId, withImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary report values of the lunch benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LunchReportDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(month, tenantId, department, filter, sortBy, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateLunchReceiptDTO} updateLunchReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateLunchReceiptDTO: UpdateLunchReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceipt(tenantId, employeeId, receiptId, updateLunchReceiptDTO, isAudit, isTenantAudit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the product classification for an ocr line item 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {string} ocrLineItemId 
         * @param {UpdateLunchReceiptOCRLineItemDTO} updateLunchReceiptOCRLineItemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReceiptOcrLineItem(tenantId: string, employeeId: string, receiptId: string, ocrLineItemId: string, updateLunchReceiptOCRLineItemDTO: UpdateLunchReceiptOCRLineItemDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReceiptOcrLineItem(tenantId, employeeId, receiptId, ocrLineItemId, updateLunchReceiptOCRLineItemDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LunchApi - factory interface
 * @export
 */
export const LunchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LunchApiFp(configuration)
    return {
        /**
         * 
         * @summary send a receipt image for synchronous OCR analysis
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchOCRReceiptDTO} createLunchOCRReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analyzeReceipt(tenantId: string, employeeId: string, createLunchOCRReceiptDTO: CreateLunchOCRReceiptDTO, options?: any): AxiosPromise<LunchReceiptOCRAnalysisDTO> {
            return localVarFp.analyzeReceipt(tenantId, employeeId, createLunchOCRReceiptDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a receipt.
         * @summary add a receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {CreateLunchReceiptDTO} createLunchReceiptDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReceipt(tenantId: string, employeeId: string, createLunchReceiptDTO: CreateLunchReceiptDTO, options?: any): AxiosPromise<IdentifierDTO> {
            return localVarFp.createReceipt(tenantId, employeeId, createLunchReceiptDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
         * @summary create a presigned upload link
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} [contentType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadLink(tenantId: string, employeeId: string, contentType?: string, options?: any): AxiosPromise<UploadLinkDTO> {
            return localVarFp.createUploadLink(tenantId, employeeId, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the receipt with the given ID. Approved receipts cannot be deleted.
         * @summary delete a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReceipt(tenantId: string, employeeId: string, receiptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReceipt(tenantId, employeeId, receiptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the current lunch period. The period will only exist if the lunch benefit is activated for the current user within the current month. Returns the active lunch period of the current user.
         * @summary read the current lunch period
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentPeriod(tenantId: string, employeeId: string, options?: any): AxiosPromise<LunchPeriodDTO> {
            return localVarFp.getCurrentPeriod(tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list of employees with the lunch benefit report values
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeReports(month: string, tenantId: string, department?: string, filter?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<EmployeeLunchReportsDTO> {
            return localVarFp.getEmployeeReports(month, tenantId, department, filter, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all lunch receipts for a tenant
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLunchReceipts(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<LunchReceiptTenantListDTO> {
            return localVarFp.getLunchReceipts(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Read the receipts of the requested period. The Lunch benefit must be active for the employee at the given month.
         * @summary read the receipts of the requested period
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodReceipts(month: string, tenantId: string, employeeId: string, options?: any): AxiosPromise<LunchReceiptsDTO> {
            return localVarFp.getPeriodReceipts(month, tenantId, employeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the periods in a range.
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} startMonth 
         * @param {string} endMonth 
         * @param {number} [maxCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods(tenantId: string, employeeId: string, startMonth: string, endMonth: string, maxCount?: number, options?: any): AxiosPromise<LunchPeriodsDTO> {
            return localVarFp.getPeriods(tenantId, employeeId, startMonth, endMonth, maxCount, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a receipt.
         * @summary read a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {boolean} [withImage] if true, imageURL will be populated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceipt(tenantId: string, employeeId: string, receiptId: string, withImage?: boolean, options?: any): AxiosPromise<LunchReceiptDTO> {
            return localVarFp.getReceipt(tenantId, employeeId, receiptId, withImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get csv with receipt details
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceiptDetailsExport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<string> {
            return localVarFp.getReceiptDetailsExport(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary report values of the lunch benefit over all employees
         * @param {string} month 
         * @param {string} tenantId 
         * @param {string} [department] 
         * @param {string} [filter] 
         * @param {Array<string>} [sortBy] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(month: string, tenantId: string, department?: string, filter?: string, sortBy?: Array<string>, page?: number, pageSize?: number, options?: any): AxiosPromise<LunchReportDTO> {
            return localVarFp.getReport(month, tenantId, department, filter, sortBy, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a receipt.
         * @summary update a single receipt
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {UpdateLunchReceiptDTO} updateLunchReceiptDTO 
         * @param {boolean} [isAudit] 
         * @param {boolean} [isTenantAudit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceipt(tenantId: string, employeeId: string, receiptId: string, updateLunchReceiptDTO: UpdateLunchReceiptDTO, isAudit?: boolean, isTenantAudit?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.updateReceipt(tenantId, employeeId, receiptId, updateLunchReceiptDTO, isAudit, isTenantAudit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the product classification for an ocr line item 
         * @param {string} tenantId 
         * @param {string} employeeId 
         * @param {string} receiptId 
         * @param {string} ocrLineItemId 
         * @param {UpdateLunchReceiptOCRLineItemDTO} updateLunchReceiptOCRLineItemDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReceiptOcrLineItem(tenantId: string, employeeId: string, receiptId: string, ocrLineItemId: string, updateLunchReceiptOCRLineItemDTO: UpdateLunchReceiptOCRLineItemDTO, options?: any): AxiosPromise<void> {
            return localVarFp.updateReceiptOcrLineItem(tenantId, employeeId, receiptId, ocrLineItemId, updateLunchReceiptOCRLineItemDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for analyzeReceipt operation in LunchApi.
 * @export
 * @interface LunchApiAnalyzeReceiptRequest
 */
export interface LunchApiAnalyzeReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiAnalyzeReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiAnalyzeReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateLunchOCRReceiptDTO}
     * @memberof LunchApiAnalyzeReceipt
     */
    readonly createLunchOCRReceiptDTO: CreateLunchOCRReceiptDTO
}

/**
 * Request parameters for createReceipt operation in LunchApi.
 * @export
 * @interface LunchApiCreateReceiptRequest
 */
export interface LunchApiCreateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiCreateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiCreateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {CreateLunchReceiptDTO}
     * @memberof LunchApiCreateReceipt
     */
    readonly createLunchReceiptDTO: CreateLunchReceiptDTO
}

/**
 * Request parameters for createUploadLink operation in LunchApi.
 * @export
 * @interface LunchApiCreateUploadLinkRequest
 */
export interface LunchApiCreateUploadLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiCreateUploadLink
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiCreateUploadLink
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiCreateUploadLink
     */
    readonly contentType?: string
}

/**
 * Request parameters for deleteReceipt operation in LunchApi.
 * @export
 * @interface LunchApiDeleteReceiptRequest
 */
export interface LunchApiDeleteReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiDeleteReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiDeleteReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiDeleteReceipt
     */
    readonly receiptId: string
}

/**
 * Request parameters for getCurrentPeriod operation in LunchApi.
 * @export
 * @interface LunchApiGetCurrentPeriodRequest
 */
export interface LunchApiGetCurrentPeriodRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetCurrentPeriod
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetCurrentPeriod
     */
    readonly employeeId: string
}

/**
 * Request parameters for getEmployeeReports operation in LunchApi.
 * @export
 * @interface LunchApiGetEmployeeReportsRequest
 */
export interface LunchApiGetEmployeeReportsRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly filter?: string

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetEmployeeReports
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getLunchReceipts operation in LunchApi.
 * @export
 * @interface LunchApiGetLunchReceiptsRequest
 */
export interface LunchApiGetLunchReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetLunchReceipts
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getPeriodReceipts operation in LunchApi.
 * @export
 * @interface LunchApiGetPeriodReceiptsRequest
 */
export interface LunchApiGetPeriodReceiptsRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriodReceipts
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriodReceipts
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriodReceipts
     */
    readonly employeeId: string
}

/**
 * Request parameters for getPeriods operation in LunchApi.
 * @export
 * @interface LunchApiGetPeriodsRequest
 */
export interface LunchApiGetPeriodsRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriods
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriods
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriods
     */
    readonly startMonth: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetPeriods
     */
    readonly endMonth: string

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetPeriods
     */
    readonly maxCount?: number
}

/**
 * Request parameters for getReceipt operation in LunchApi.
 * @export
 * @interface LunchApiGetReceiptRequest
 */
export interface LunchApiGetReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceipt
     */
    readonly receiptId: string

    /**
     * if true, imageURL will be populated
     * @type {boolean}
     * @memberof LunchApiGetReceipt
     */
    readonly withImage?: boolean
}

/**
 * Request parameters for getReceiptDetailsExport operation in LunchApi.
 * @export
 * @interface LunchApiGetReceiptDetailsExportRequest
 */
export interface LunchApiGetReceiptDetailsExportRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetReceiptDetailsExport
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getReport operation in LunchApi.
 * @export
 * @interface LunchApiGetReportRequest
 */
export interface LunchApiGetReportRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReport
     */
    readonly month: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReport
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReport
     */
    readonly department?: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiGetReport
     */
    readonly filter?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof LunchApiGetReport
     */
    readonly sortBy?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetReport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LunchApiGetReport
     */
    readonly pageSize?: number
}

/**
 * Request parameters for updateReceipt operation in LunchApi.
 * @export
 * @interface LunchApiUpdateReceiptRequest
 */
export interface LunchApiUpdateReceiptRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceipt
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceipt
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceipt
     */
    readonly receiptId: string

    /**
     * 
     * @type {UpdateLunchReceiptDTO}
     * @memberof LunchApiUpdateReceipt
     */
    readonly updateLunchReceiptDTO: UpdateLunchReceiptDTO

    /**
     * 
     * @type {boolean}
     * @memberof LunchApiUpdateReceipt
     */
    readonly isAudit?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof LunchApiUpdateReceipt
     */
    readonly isTenantAudit?: boolean
}

/**
 * Request parameters for updateReceiptOcrLineItem operation in LunchApi.
 * @export
 * @interface LunchApiUpdateReceiptOcrLineItemRequest
 */
export interface LunchApiUpdateReceiptOcrLineItemRequest {
    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceiptOcrLineItem
     */
    readonly tenantId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceiptOcrLineItem
     */
    readonly employeeId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceiptOcrLineItem
     */
    readonly receiptId: string

    /**
     * 
     * @type {string}
     * @memberof LunchApiUpdateReceiptOcrLineItem
     */
    readonly ocrLineItemId: string

    /**
     * 
     * @type {UpdateLunchReceiptOCRLineItemDTO}
     * @memberof LunchApiUpdateReceiptOcrLineItem
     */
    readonly updateLunchReceiptOCRLineItemDTO: UpdateLunchReceiptOCRLineItemDTO
}

/**
 * LunchApi - object-oriented interface
 * @export
 * @class LunchApi
 * @extends {BaseAPI}
 */
export class LunchApi extends BaseAPI {
    /**
     * 
     * @summary send a receipt image for synchronous OCR analysis
     * @param {LunchApiAnalyzeReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public analyzeReceipt(requestParameters: LunchApiAnalyzeReceiptRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).analyzeReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createLunchOCRReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a receipt.
     * @summary add a receipt
     * @param {LunchApiCreateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public createReceipt(requestParameters: LunchApiCreateReceiptRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).createReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.createLunchReceiptDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a form POST upload link for uploading the receipt image. Refer to https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-post-example.html on how to implement the form POST.
     * @summary create a presigned upload link
     * @param {LunchApiCreateUploadLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public createUploadLink(requestParameters: LunchApiCreateUploadLinkRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).createUploadLink(requestParameters.tenantId, requestParameters.employeeId, requestParameters.contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the receipt with the given ID. Approved receipts cannot be deleted.
     * @summary delete a single receipt
     * @param {LunchApiDeleteReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public deleteReceipt(requestParameters: LunchApiDeleteReceiptRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).deleteReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the current lunch period. The period will only exist if the lunch benefit is activated for the current user within the current month. Returns the active lunch period of the current user.
     * @summary read the current lunch period
     * @param {LunchApiGetCurrentPeriodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getCurrentPeriod(requestParameters: LunchApiGetCurrentPeriodRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getCurrentPeriod(requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list of employees with the lunch benefit report values
     * @param {LunchApiGetEmployeeReportsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getEmployeeReports(requestParameters: LunchApiGetEmployeeReportsRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getEmployeeReports(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all lunch receipts for a tenant
     * @param {LunchApiGetLunchReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getLunchReceipts(requestParameters: LunchApiGetLunchReceiptsRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getLunchReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read the receipts of the requested period. The Lunch benefit must be active for the employee at the given month.
     * @summary read the receipts of the requested period
     * @param {LunchApiGetPeriodReceiptsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getPeriodReceipts(requestParameters: LunchApiGetPeriodReceiptsRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getPeriodReceipts(requestParameters.month, requestParameters.tenantId, requestParameters.employeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the periods in a range.
     * @param {LunchApiGetPeriodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getPeriods(requestParameters: LunchApiGetPeriodsRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getPeriods(requestParameters.tenantId, requestParameters.employeeId, requestParameters.startMonth, requestParameters.endMonth, requestParameters.maxCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a receipt.
     * @summary read a single receipt
     * @param {LunchApiGetReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getReceipt(requestParameters: LunchApiGetReceiptRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.withImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get csv with receipt details
     * @param {LunchApiGetReceiptDetailsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getReceiptDetailsExport(requestParameters: LunchApiGetReceiptDetailsExportRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getReceiptDetailsExport(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary report values of the lunch benefit over all employees
     * @param {LunchApiGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public getReport(requestParameters: LunchApiGetReportRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).getReport(requestParameters.month, requestParameters.tenantId, requestParameters.department, requestParameters.filter, requestParameters.sortBy, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a receipt.
     * @summary update a single receipt
     * @param {LunchApiUpdateReceiptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public updateReceipt(requestParameters: LunchApiUpdateReceiptRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).updateReceipt(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.updateLunchReceiptDTO, requestParameters.isAudit, requestParameters.isTenantAudit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the product classification for an ocr line item 
     * @param {LunchApiUpdateReceiptOcrLineItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LunchApi
     */
    public updateReceiptOcrLineItem(requestParameters: LunchApiUpdateReceiptOcrLineItemRequest, options?: AxiosRequestConfig) {
        return LunchApiFp(this.configuration).updateReceiptOcrLineItem(requestParameters.tenantId, requestParameters.employeeId, requestParameters.receiptId, requestParameters.ocrLineItemId, requestParameters.updateLunchReceiptOCRLineItemDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
