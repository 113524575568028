import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { CouponCard, CouponOrderDetails } from '../../module/coupons';
import { useCouponOrderQuery } from '../../module/coupons/couponQueries';
import { useBackNavigator } from '../../module/navigation/BackNavigator';

export const CouponOrderPage: React.FC = () => {
  const backNavigator = useBackNavigator();
  const { couponOrderId } = useParams();
  const theme = useTheme();

  const { data: couponOrder, isFetching } = useCouponOrderQuery(couponOrderId!);
  const couponDefinition = couponOrder?.couponDefinition as Record<string, string> | undefined;

  return (
    <MobileLayout
      header={
        <AppToolbar
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <>
              {couponDefinition?.redeemUrl && (
                <IconButton href={couponDefinition.redeemUrl} target="_blank">
                  <ShoppingCartOutlinedIcon />
                </IconButton>
              )}
            </>
          }
        />
      }
      backgroundColor={theme.palette.background.default}
    >
      <Box sx={{ paddingX: 1, paddingBottom: 1 }}>
        {!isFetching && couponOrder ? (
          <CouponOrderDetails couponOrder={couponOrder} />
        ) : (
          <CouponCard>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <CircularProgress />
            </Box>
          </CouponCard>
        )}
      </Box>
    </MobileLayout>
  );
};
