import { Alert, Box, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BackIconButton } from '../../component/button/BackIconButton';
import { ButtonTab } from '../../component/button/CircularFloatingButton';
import { ImageFile } from '../../component/camera/ImageFile';
import { MobileLayout } from '../../component/layout';
import { AppToolbar } from '../../component/layout/AppToolbar';
import { HighlightGuideButton } from '../../module/guiding/HighlightGuideButton';
import { AddReceiptButton, LunchAmount } from '../../module/lunch';
import { LunchPeriods } from '../../module/lunch/LunchPeriods';
import { useBackNavigator } from '../../module/navigation/BackNavigator';
import { BenefitDTOBenefitEnum } from 'probonio-shared-ui/api';
import { useLunchPeriods } from '../../module/lunch/LunchPeriodsContext';
import { useTenant } from 'probonio-shared-ui/module/me';

interface Props {
  onSelectImage: (file: ImageFile, pdf?: Blob) => void;
}

const LunchPage: React.FC<Props> = ({ onSelectImage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('benefitModule');
  const theme = useTheme();
  const backNavigator = useBackNavigator();
  const [selectTab, setSelectedTab] = useState(ButtonTab.right);
  const { currentPeriod } = useLunchPeriods();
  const { tenant } = useTenant();

  const handleSelectImage = useCallback(
    (file: ImageFile, pdf?: Blob) => {
      onSelectImage(file, pdf);
      navigate('receipt/add');
    },
    [onSelectImage, navigate],
  );
  const navigateInfo = useCallback(() => {
    navigate('info');
  }, [navigate]);

  return (
    <MobileLayout
      iosOverloadScrollBackground={theme.palette.background.paper}
      header={
        <AppToolbar
          title={t('benefit.LUNCH.name')}
          color={theme.palette.background.paper}
          backButton={<BackIconButton onClick={backNavigator.back} />}
          actionButton={
            <HighlightGuideButton
              name={t('benefit.LUNCH.name')}
              header={t('benefit.LUNCH.firstHelpGuide.header')}
              text={t('benefit.LUNCH.firstHelpGuide.text')}
              onClick={navigateInfo}
            />
          }
        />
      }
    >
      <LunchAmount selectedTab={selectTab} />
      <AddReceiptButton
        benefit={BenefitDTOBenefitEnum.Lunch}
        data-test-id="add-receipt-button"
        onSelect={handleSelectImage}
        selectedTab={selectTab}
        handleTabChange={setSelectedTab}
        disabled={currentPeriod?.isLocked}
      />
      <Box sx={{ padding: 1, paddingTop: 0.5 }}>
        {!!tenant?.lunchPeriodCloseDelay && tenant.lunchPeriodCloseDelay < 0 && (
          <Alert severity="warning" sx={{ marginBottom: 0.5 }}>
            {t('benefit.LUNCH.periodCloseDelayWarning', { count: -tenant.lunchPeriodCloseDelay })}
          </Alert>
        )}
        <LunchPeriods />
      </Box>
    </MobileLayout>
  );
};

export default LunchPage;
